import { ref, onMounted, onUnmounted } from 'vue'

export function useWindowSize() {
	const width = ref(0) // 기본값을 0으로 설정
	const height = ref(0)

	// 화면 크기를 업데이트하는 함수
	const updateWindowSize = () => {
		width.value = window.innerWidth
		height.value = window.innerHeight
	}

	onMounted(() => {
		if (process.client) {
			// 클라이언트에서만 실행
			// 초기 화면 크기 설정
			updateWindowSize()

			// 리사이즈 이벤트 리스너 추가
			window.addEventListener('resize', updateWindowSize)
		}
	})

	onUnmounted(() => {
		if (process.client) {
			// 리스너 제거
			window.removeEventListener('resize', updateWindowSize)
		}
	})

	return { width, height }
}
